import { BUILD_TIMESTAMP } from '@environments/timestamp';
const packageJson = require('../../package.json');

export const environment = {
  appName: 'Complete Frontend Boilerplate',
  production: false,
  hmr: false,
  name: 'Acceptance',
  baseUrl: 'https://sparkling-cloud-j39zxdwuzbgj.vapor-farm-f1.com/api',
  sentryUrl: null,
  databaseName: 'emendis.complete',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  redirectAfterLogin: '/',
  url: 'http://localhost:4200'
};
