<app-dialog-layout [title]="title">
  <ng-container content>
    <cpb-form-renderer [formKey]="formKey" [showHeader]="false" [modelId]="modelId"></cpb-form-renderer>
  </ng-container>

  <ng-container buttons>
    <cap-button
      styleClass="secondary"
      [label]="'button.cancel' | translate"
      icon="fas fa-times"
      (click)="cancel()"></cap-button>
    <cap-button
      styleClass="success"
      [label]="'button.submit' | translate"
      icon="fas fa-save"
      (click)="submit()"></cap-button>
  </ng-container>
</app-dialog-layout>
