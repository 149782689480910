import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@capturum/auth';
import { CompleteResolver, IndexedDbGuard, PublicTranslationResolver } from '@capturum/complete';
import { loginRoutes } from '@capturum/login';
import { BasicLayoutComponent } from '@core/components/basic-layout/basic-layout.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { AppRoutes } from '@core/enums/routes.enum';

const routes: Routes = [
  {
    ...loginRoutes[0],
    canActivate: [IndexedDbGuard],
    resolve: {
      publicTranslations: PublicTranslationResolver,
    },
    children: [...loginRoutes[0].children],
  },
  {
    path: '',
    canActivate: [IndexedDbGuard, AuthGuard],
    resolve: {
      complete: CompleteResolver,
    },
    children: [
      {
        path: '',
        component: BasicLayoutComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: AppRoutes.articles,
          },
          {
            path: AppRoutes.articles,
            loadChildren: () => {
              return import('./features/article/routes').then((m) => {
                return m.default;
              });
            },
          },
          {
            path: AppRoutes.suppliers,
            loadChildren: () => {
              return import('./features/supplier/routes').then((m) => {
                return m.default;
              });
            },
          },
          {
            path: AppRoutes.goodsReceival,
            loadChildren: () => {
              return import('./features/goods-receival/routes').then((m) => {
                return m.default;
              });
            },
          },
          {
            path: AppRoutes.qualityInformationLines,
            loadChildren: () => {
              return import('./features/quality-information-line/routes').then((m) => {
                return m.default;
              });
            },
          },
          {
            path: AppRoutes.locations,
            loadChildren: () => {
              return import('./features/locations/routes').then((m) => {
                return m.default;
              });
            },
          },
          {
            path: 'roles',
            loadChildren: () => {
              return import('./features/role/role.module').then((m) => {
                return m.RoleModule;
              });
            },
            data: {
              permissions: {
                only: 'role.manage.tenant',
                redirectTo: '/',
              },
            },
          },
          {
            path: 'translations',
            loadChildren: () => {
              return import('./features/translation/translation.module').then((m) => {
                return m.TranslationModule;
              });
            },
          },
          {
            path: 'users',
            loadChildren: () => {
              return import('./features/user/user.module').then((m) => {
                return m.UserModule;
              });
            },
          },
          {
            path: 'base-data',
            loadChildren: () => {
              return import('./features/base-data/base-data.module').then((m) => {
                return m.BaseDataModule;
              });
            },
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: 'base-data-value.manage.tenant',
                redirectTo: '/',
              },
            },
          },
          {
            path: 'settings',
            loadChildren: () => {
              return import('./features/settings/settings.module').then((m) => {
                return m.SettingsModule;
              });
            },
          },
          {
            path: 'config',
            loadChildren: () => {
              return import('./features/config/config.module').then((m) => {
                return m.ConfigModule;
              });
            },
          },
          {
            path: 'builders',
            loadChildren: () => {
              return import('@capturum/builders/pages').then((m) => {
                return m.CapturumBuilderPagesModule;
              });
            },
          },
          {
            path: '',
            loadChildren: () => {
              return import('@capturum/complete').then((complete) => {
                return complete.CompleteModule;
              });
            },
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: AppRoutes.goodsReceival,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
