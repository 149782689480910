import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslationLoader } from '@capturum/complete';
import { CoreModule } from '@core/core.module';
import { ConfirmationService } from 'primeng/api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppInjector } from './app-injector.service';
import { CapturumToastModule } from '@capturum/ui/toast';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CapturumButtonModule } from '@capturum/ui/button';
import { NgxsModule } from '@ngxs/store';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { CapturumBuilderCoreModule } from '@capturum/builders/core';
import { LocationDetailComponent } from './features/locations/pages/location-detail/location-detail.component';
import { PurchaseOrderStatusWidgetComponent } from '@shared/builders/list-widgets/purchase-order-status-widget/purchase-order-status-widget.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader,
      },
    }),
    CoreModule,
    CapturumToastModule,
    CapturumListRendererModule.forRoot({
      widgets: {
        purchase_order_status: PurchaseOrderStatusWidgetComponent,
      },
    }),
    CapturumFormRendererModule.forRoot({
      translationKeyPrefix: '',
    }),
    ConfirmDialogModule,
    CapturumButtonModule,
    CapturumBuilderCoreModule.forRoot({
      baseUrl: '/builders',
      components: {
        list_locations_add: LocationDetailComponent,
        list_locations_edit: LocationDetailComponent,
      },
    }),
    NgxsModule.forRoot(),
  ],
  providers: [ConfirmationService],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
