import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogLayoutComponent } from '@core/components/dialog-layout/dialog-layout.component';
import { CapturumFormRendererModule, FormSaverService } from '@capturum/builders/form-renderer';
import { CapturumButtonModule } from '@capturum/ui/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { first } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CapturumBuilderActionService } from '@capturum/builders/core';

@Component({
  selector: 'app-location-detail',
  standalone: true,
  imports: [CommonModule, DialogLayoutComponent, CapturumFormRendererModule, CapturumButtonModule, TranslateModule],
  templateUrl: './location-detail.component.html',
  styleUrls: ['./location-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationDetailComponent implements OnInit {
  public modelId: string;
  public formKey = 'form_location';
  public title: string;

  constructor(
    private formSaver: FormSaverService,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private translateService: TranslateService,
    private actionService: CapturumBuilderActionService,
  ) {}

  public ngOnInit(): void {
    this.modelId = this.dialogConfig.data?.item?.id;

    const translation = this.modelId ? 'platvis.location.edit.title' : 'platvis.location.add.title';

    this.title = this.translateService.instant(translation);
  }

  public submit(): void {
    this.formSaver
      .submit(this.formKey)
      .pipe(first())
      .subscribe(() => {
        this.actionService.broadcastActionExecuted(
          { key: 'refresh', type: '', options: { refresh: true } },
          null,
          null,
        );

        this.dialogRef.close();
      });
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
